import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "content"]

  connect() {
    useClickOutside(this)
  }

  close(event) {
    if (event.target === this.modalTarget) {
      this.hide()
    }
  }

  hide() {
    this.modalTarget.classList.remove("modal")
  }
}
